// TODO: opsplitsen in verschillende scss-bestanden
$card-spacer-y: 1rem !default;
$card-spacer-x: 1rem !default;

$primary: #062231;
$secondary: #cbcecf;
$light: #f9f9f9;

$link: #2c84be;

/* Nav tabs */
//$nav-tabs-link-active-color: $primary;
//$nav-tabs-link-active-bg: $light;

/* Links */
$nav-link-color: $primary;
$nav-link-disabled-color: #ced4da;
$nav-link-hover-color: $link;
$nav-link-active-color: $link;

@import "./mdb-react-ui-kit/scss/mdb.pro.scss";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~react-image-lightbox/style.css";

html {
  overflow: hidden;
  overscroll-behavior: none;
}

html, body, #root {
  height: 100%;

  background-color: #fff;
}

body {
  font-family: Roboto, Helvetica, Arial, sans-serif;
}

.dark-overlay {
  position: relative;

  &::after {
    content: "";

    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;

    background-color: rgba(0, 0, 0, 0.5);
  }
}

.generic-page {
  > .container, > .container-fluid {
    padding-bottom: 4rem !important;
  }
}

input::placeholder {
  /* Override MDBootstrap's default hidden placeholder */
  opacity: 0.4 !important;
}

/* Links */
.nav-link.active:not(.disabled) {
  color: $nav-link-active-color;
}

.sidenav-link.active:not(.disabled) {
  color: $link;
}

/* Lightbox fix */
.ReactModal__Overlay {
  z-index: 5000 !important;
}

/* Timeline */
.timeline-drive {
  position: relative;
  padding-left: 2rem;

  &:before {
    content: "";

    display: block;
    position: absolute;

    top: 0;
    left: 1rem;
    bottom: 0;

    border-left: 2px solid #ced4da;
  }

  .dot {
    display: block;
    position: absolute;

    top: 50%;
    left: 1rem; /* Half the padding-left */

    transform: translateX(calc(-50% + 1px)) translateY(-50%);

    background-color: white;
  }
}

/* Servicelog timeline */
ul.timeline {
  display: flex;
  flex-direction: column;

  list-style: none;
  overflow: visible;

  > li {
    border-left: 2px solid #ced4da;
    padding-left: 2rem;
    padding-bottom: 2rem;

    margin: 0;

    position: relative;

    &:before {
      content: "";

      position: absolute;

      left: -1px; // Helft van 2px border
      top: 0;

      width: 1rem;
      height: 1rem;

      background-color: var(--mdb-primary);
      border-radius: 50%;

      margin-left: -0.5rem;
    }
  }
}

/* Card status */
.card {
  overflow: hidden;
}

.card-status {
  position: absolute;

  width: 6px;

  z-index: 10;

  top: 0;
  left: 0;
  bottom: 0;
}

/* Signature wrapper */
.signature-wrapper {
  border: 1px solid #bdbdbd !important;
  border-radius: 0.25rem;

  position: relative;

  &.disabled {
    pointer-events: none;
  }

  &.is-invalid {
    border: 1px solid #f93154 !important;
  }

  .clear-button {
    display: block;
    position: absolute;
    right: 1rem;
    bottom: 1rem;
  }
}

/* Tabs font size fix */
.nav-tabs .nav-link {
  font-size: 1rem;
}

/* Dropdown */
.dropdown-item {
  font-size: 1rem;
}

.dropdown-toggle {
  &.no-arrow {
    &::after {
      display: none;
    }
  }
}

/* Top navbar */
.top-navbar {
  //height: 3.5rem;
}

nav.navbar {
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05); // Fix spread at top (for subtitle in header)
}

/* Bottom navbar */
.bottom-navbar {
  height: 4rem;
}

/* Sidenav */
.sidenav {
  width: auto;

  .scrollbar-container {
    display: flex;
    flex-direction: column;
  }
}

.sidenav-link {
  font-size: 1rem;
}

.sidenav-backdrop {
  /* Backdrop is 1035, top and bottom nav is 1030 */
  z-index: 1034;
  /* Position fix */
  position: fixed !important;
}

/**
 * Modal
 * Always position absolute without media query
 */
.modal-frame {
  position: absolute;
  max-width: 100%;
  width: 100%;
  margin: 0;
}

.modal {
  .modal-dialog {
    &.modal-top {
      top: 0;
    }

    &.modal-left {
      left: 0;
    }

    &.modal-right {
      right: 0;
    }

    &.modal-bottom {
      bottom: 0;
    }

    &.modal-top-left {
      top: 10px;
      left: 10px;
    }

    &.modal-top-right {
      top: 10px;
      right: 10px;
    }

    &.modal-bottom-left {
      bottom: 10px;
      left: 10px;
    }

    &.modal-bottom-right {
      right: 10px;
      bottom: 10px;
    }
  }
}

/* Borderless select */
.select-wrapper {
  &.hide-arrow {
    .select-arrow {
      display: none;
    }
  }

  &.text-white {
    input {
      color: #fff;
    }

    .select-arrow {
      color: #fff;
    }
  }

  &.p-0 {
    input {
      padding: 0;
    }
  }

  &.borderless {
    .form-notch {
      display: none;
    }
  }

  &.text-center {
    input {
      text-align: center;
    }
  }

  &.navbar-title, &.navbar-title-big {
    input {
      font-size: 1.3rem;
    }
  }

  &.navbar-title-big {
    input {
      font-size: 1.6rem;
    }
  }

  .form-outline {
    position: relative;
  }
}

/* Utilities */
.border-radius-circle {
  border-radius: 50%;
}

.overflow-y-auto {
  overflow-y: auto;
}

.navbar-title {
  font-size: 1.3rem;
}

.navbar-title-big {
  font-size: 1.6rem;
}

.flex-even {
  flex: 1;
}

.font-1rem {
  font-size: 1rem;
}

.font-smaller {
  font-size: 0.9rem;
}

.font-bigger {
  font-size: 1.1rem;
}

.font-weight-semibold {
  font-weight: 500;
}

.text-right {
  text-align: right;
}

.shadow-inset {
  box-shadow: $box-shadow-inset;
}

.border-radius-0 {
  border-radius: 0;
}

.border-top-left-radius-0 {
  border-top-left-radius: 0;
}

.border-top-right-radius-0 {
  border-top-right-radius: 0;
}

.border-bottom-left-radius-0 {
  border-bottom-left-radius: 0;
}

.border-bottom-right-radius-0 {
  border-bottom-right-radius: 0;
}

.cursor-pointer {
  cursor: pointer;
}

.bg-cover {
  background-size: cover;
}

.bg-contain {
  background-size: contain;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-center {
  background-position: center;
}

.img-cover {
  object-fit: cover;
}

.table.table--no-bottom-border {
  tr:last-child {
    border-bottom: transparent;
  }
}

.shadow-top {
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.15) !important;
}

/* Hide Google Maps UI */
a[href^="http://maps.google.com/maps"] {
  display: none !important
}

a[href^="https://maps.google.com/maps"] {
  display: none !important
}

.gmnoprint a, .gmnoprint span, .gm-style-cc {
  display: none;
}

.gmnoprint div {
  background: none !important;
}

/* Bigger select */
.select-options-wrapper {
  max-height: 20rem !important;

  .select-options-list {
    .select-option {
      height: 3.2rem !important;
      font-size: 1.4rem !important;
    }
  }
}

/* Active map marker */
.map-marker {
  &.active {
    transform: scale(1);
    animation: pulse 2s infinite;
  }
}

/* LocationPin */
.location-pin {
  border-radius: 50%;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  height: 20px;
  width: 20px;

  background-color: #4e86f5;
  border: 2px solid white;

  transform: scale(1);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(52, 172, 224, 0);
  }

  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(52, 172, 224, 0);
  }
}

.monospaced-letterbox {
  > span {
    display: inline-block;
    padding: 0.2em;
    margin-right: 2px;
    border-radius: 2px;

    background: var(--mdb-primary);
    color: var(--mdb-light);
  }
}

.card-disabled-overlay {
  position: absolute;

  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 9000;

  background-color: rgba(0, 0, 0, 0.05);

  > span {
    display: inline-block;
    position: absolute;

    background-color: var(--mdb-warning);
    opacity: 0.9;
    color: #fff;

    bottom: 0;
    left: 0;
    right: 0;

    padding: 0.5rem 0.8rem;

    box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.15) !important;
  }
}

.pointer-events-none {
  pointer-events: none;
}

/* Fix MDBSelect dropdown wider than input */
div[data-popper-placement] {
  width: auto !important;

  z-index: 10000;
}

.dag-planning-entry {
  position: relative;
  overflow: visible;

  display: block;

  > .card {
    z-index: 300;

    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.08);
  }

  &.pulse {
    &:before {
      content: "";
      position: absolute;
      display: block;

      left: 0;
      top: 0;
      right: 0;
      bottom: 0;

      margin: -0.8rem;
      z-index: 100;

      background-color: #f6f111;

      animation: card-pulse 1.2s infinite;
    }
  }
}

@keyframes card-pulse {
  0% {
    opacity: 0;
  }

  70% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

/* MDBSelect validation */
.select-wrapper.is-invalid {
  .form-control {
    margin-bottom: 1rem;
    background-image: none;
    border-color: var(--mdb-danger);
  }

  .form-notch-leading, .form-notch-middle, .form-notch-trailing {
    border-color: var(--mdb-danger) !important;
  }

  label {
    color: var(--mdb-danger) !important;
  }

  .invalid-feedback {
    display: block;
  }
}

/* Form check fix */
.form-check {
  display: flex !important;

  input {
    flex-shrink: 0;
  }
}

.no-outline {
  &:focus {
    outline: none;
  }
}

.form-notch-fix {
  .form-notch {
    display: none !important;
  }
}
